import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=48315f46&"
import script from "./Account.vue?vue&type=script&lang=ts&"
export * from "./Account.vue?vue&type=script&lang=ts&"
import style0 from "./Account.vue?vue&type=style&index=0&id=48315f46&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QInnerLoading,QSpinnerTail,QForm,QInput,QBtn,QUploader} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QInnerLoading,QSpinnerTail,QForm,QInput,QBtn,QUploader})
